import React, {useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';
import Copy from '@hoffman/shared/src/components/TextFeatures/copy';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.compat.css';

const DesignIntroContentBlock = (props) => {
  const {dataJson} = useStaticQuery<
    GatsbyTypes.DesignIntroContentBlockQuery
  >(graphql`
    query DesignIntroContentBlock {
      dataJson(slug: {eq: "design"}) {
        slug
        panels(name: "designIntroContentPanel") {
          name
          models {
            type
            fields {
              text
              url
              newTab
            }
          }
        }
      }
    }
  `);
  const items = dataJson.panels[0].models.reduce(
    (acc: object, m: {type: string; fields: any}) => {
      acc[m.type] = m.fields;
      return acc;
    },
    {},
  );
  const theme = useContext(ThemeContext);

  return (
    <ScrollAnimation
      animateIn="fadeInLeft"
      animateOnce={true}
      duration={2}
      initiallyVisible={false}
      offset={10}
    >
      <StyledDiv>
        <Copy
          text={items.copy?.text}
          size={`${theme.fontSizes[5]}px`}
          blockWidth="810px"
          subHeader={true}
        />
      </StyledDiv>
    </ScrollAnimation>
  );
};

export default DesignIntroContentBlock;

const StyledDiv = styled.div`
  p {
    font-size: 32px;
    line-height: 1.25em;
    @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
      font-size: 16px;
      line-height: 25px;
    }
  }
`;
