import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import ExpandImg from '@hoffman/shared/src/components/ExpandImg';
import fluidImgPropsFromModelFields from './helpers/fluidImgPropsFromModelFields';

const DesignSecondImageBlock = () => {
  const {dataJson} = useStaticQuery<
    GatsbyTypes.DesignSecondImageBlockQuery
  >(graphql`
    query DesignSecondImageBlock {
      dataJson(slug: {eq: "design"}) {
        panels(name: "designSecondaryImagePanel") {
          name
          models {
            type
            fields {
              asset {
                childImageSharp {
                  fluid(maxWidth: 3000, quality: 95) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              mobileAsset {
                childImageSharp {
                  fluid(maxWidth: 3000, quality: 95) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
              objectPosition
            }
          }
        }
      }
    }
  `);
  const fields = dataJson?.panels?.[0]?.models?.[0]?.fields;
  const fluid = fields?.asset?.childImageSharp?.fluid;
  const mobile = fields?.mobileAsset?.childImageSharp?.fluid;
  const objectPosition = fields?.objectPosition;

  return <>{fluid && <ExpandImg {...{fluid, mobile, objectPosition}} />}</>;
};

export default DesignSecondImageBlock;
