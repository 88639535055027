import React, {useContext} from 'react';
import {ThemeContext} from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';
import QuotePageSection from '@hoffman/shared/src/components/page-sections/QuotePageSection';

const DesignThirdQuoteBlock = (props) => {
  const {dataJson} = useStaticQuery<
    GatsbyTypes.DesignThirdQuoteBlockQuery
  >(graphql`
    query DesignThirdQuoteBlock {
      dataJson(slug: {eq: "design"}) {
        slug
        panels(name: "designThirdQuotePanel") {
          name
          models {
            type
            fields {
              text
              cite
            }
          }
        }
      }
    }
  `);
  const items = dataJson.panels[0].models.reduce(
    (acc: object, m: {type: string; fields: any}) => {
      acc[m.type] = m.fields;
      return acc;
    },
    {},
  );

  const theme = useContext(ThemeContext);
  return (
    <>
      <QuotePageSection
        headlineSize="52px"
        headlineText={items.quote?.text}
        copyText={items.quote?.cite}
        copySize={`${theme.fontSizes[1]}px`}
        quoteColor={theme.colors.text}
        quoteSize={`${theme.fontSizes[5]}px`}
        blockWidth="1080px"
      />
    </>
  );
};

export default DesignThirdQuoteBlock;
