import React, {useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';
import ContentPageSection from '@hoffman/shared/src/components/page-sections/ContentPageSection';

const DesignSecondContentBlock = (props) => {
  const {dataJson} = useStaticQuery<
    GatsbyTypes.DesignSecondContentBlockQuery
  >(graphql`
    query DesignSecondContentBlock {
      dataJson(slug: {eq: "design"}) {
        slug
        panels(name: "designSecondContentPanel") {
          name
          models {
            type
            fields {
              text
              url
              newTab
            }
          }
        }
      }
    }
  `);
  const items = dataJson.panels[0].models.reduce(
    (acc: object, m: {type: string; fields: any}) => {
      acc[m.type] = m.fields;
      return acc;
    },
    {},
  );
  const theme = useContext(ThemeContext);

  return (
    <Container>
      <ContentPageSection
        bodyCopyPosition="center"
        blockWidth="480px"
        headlineSize={`${theme.fontSizes[5]}px`}
        headlineText={items.headline?.text}
        copySize={`${theme.fontSizes[2]}px`}
        copyText={items.copy?.text}
        rightPosition={false}
        topPosition={false}
        cta={false}
        verticalText={items.verticalTag?.text}
        verticalColor="grey"
      />
    </Container>
  );
};

export default DesignSecondContentBlock;

const Container = styled.div`
  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    max-width: 330px;
  }
`;
