import React from 'react';
import {Link} from 'gatsby';

import BaseLayout from '@hoffman/shared/src/components/BaseLayout';
import SEO from '../../../../packages/docs/src/components/seo';
import NavbarBlock from '@hoffman/amaris/src/blocks/NavbarBlock';
import DesignInteriorHeadlineBlock from '~blocks/DesignInteriorHeadlineBlock';
import DesignIntroImageBlock from '~blocks/DesignIntroImageBlock';
import DesignIntroContentBlock from '~blocks/DesignIntroContentBlock';
import DesignSecondImageBlock from '~blocks/DesignSecondImageBlock';
import DesignSecondContentBlock from '~blocks/DesignSecondContentBlock';
import DesignThirdImageExpandBlock from '~blocks/DesignThirdImageExpandBlock';
import DesignThirdQuoteBlock from '~blocks/DesignThirdQuoteBlock';
import DesignFourthImageBlock from '~blocks/DesignFourthImageBlock';
import DesignFourthContentBlock from '~blocks/DesignFourthContentBlock';
import DesignFifthImageExpandBlock from '~blocks/DesignFifthImageExpandBlock';
import DesignFifthQuoteBlock from '~blocks/DesignFifthQuoteBlock';
import DesignCTABannerBlock from '~blocks/DesignCTABannerBlock';
import FooterBlock from '~blocks/FooterBlock';
import Section from '@hoffman/shared/src/components/Section';
import SectionBackgroundGroup from '@hoffman/shared/src/components/SectionBackgroundGroup';
import SectionPadding from '@hoffman/shared/src/components/SectionPadding';
import {useStaticQuery, graphql} from 'gatsby';

const SecondPage = () => {
  const {dataJson} = useStaticQuery(
    graphql`
      query {
        dataJson(slug: {eq: "design"}) {
          id
          seoDescription
          seoTitle
          bg {
            desktop {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 95) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mobile {
              childImageSharp {
                fluid(maxWidth: 3000, quality: 95) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `,
  );

  const bgImgs = {
    desktop1: dataJson?.bg[0]?.desktop?.childImageSharp?.fluid,
    mobile1: dataJson?.bg[0]?.mobile?.childImageSharp?.fluid,
    desktop2: dataJson?.bg[1]?.desktop?.childImageSharp?.fluid,
  };
  return (
    <BaseLayout>
      <SEO title={dataJson.seoTitle} description={dataJson.seoDescription} />
      <NavbarBlock />
      <SectionBackgroundGroup altBg={true}>
        <SectionPadding py="shorter">
          <DesignInteriorHeadlineBlock />
        </SectionPadding>
      </SectionBackgroundGroup>

      <SectionBackgroundGroup bgImg={bgImgs.desktop2}>
        <SectionPadding py="none" px="none">
          <DesignIntroImageBlock />
        </SectionPadding>
        <SectionPadding py="tall" px="narrowest">
          <DesignIntroContentBlock />
        </SectionPadding>
      </SectionBackgroundGroup>

      <Section themeName="DARK">
        <SectionBackgroundGroup
          bgImg={bgImgs.desktop1}
          bgImgMobile={bgImgs.mobile1}
        >
          <SectionPadding py="none" px="none">
            <DesignSecondImageBlock />
          </SectionPadding>
          <SectionPadding py="tall">
            <DesignSecondContentBlock />
          </SectionPadding>

          <SectionPadding py="none" px="none">
            <DesignThirdImageExpandBlock />
          </SectionPadding>
          <SectionPadding py="tall">
            <DesignThirdQuoteBlock />
          </SectionPadding>
        </SectionBackgroundGroup>
      </Section>

      <SectionBackgroundGroup>
        <SectionPadding py="none" px="none">
          <DesignFourthImageBlock />
        </SectionPadding>
        <SectionPadding py="tall">
          <DesignFourthContentBlock />
        </SectionPadding>

        <SectionPadding py="none" px="none">
          <DesignFifthImageExpandBlock />
        </SectionPadding>
        <SectionPadding py="tall">
          <DesignFifthQuoteBlock />
        </SectionPadding>
      </SectionBackgroundGroup>

      <Section themeName="DARK">
        <SectionBackgroundGroup>
          <SectionPadding>
            <DesignCTABannerBlock />
          </SectionPadding>
        </SectionBackgroundGroup>
      </Section>
      <FooterBlock />
    </BaseLayout>
  );
};
export default SecondPage;
