import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import CTABannerPageSection from '@hoffman/shared/src/components/page-sections/CTABannerPageSection';

const DesignCTABannerBlock = (props) => {
  const {dataJson} = useStaticQuery<
    GatsbyTypes.DesignCTABannerBlockQuery
  >(graphql`
    query DesignCTABannerBlock {
      dataJson(slug: {eq: "design"}) {
        slug
        panels(name: "designCTABanner") {
          name
          models {
            type
            fields {
              text
              url
              newTab
            }
          }
        }
      }
    }
  `);
  const items = dataJson.panels[0].models.reduce(
    (acc: object, m: {type: string; fields: any}) => {
      acc[m.type] = m.fields;
      return acc;
    },
    {},
  );
  return (
    <div>
      <CTABannerPageSection
        headline={items.headline?.text}
        copy={items.copy?.text}
        cta={items.link && items.link.text}
        link={items.link && items.link.url}
      />
    </div>
  );
};

export default DesignCTABannerBlock;
