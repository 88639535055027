import React from 'react';
import styled from 'styled-components';
import {Quote} from '@hoffman/shared/src/components/Quote/quote';
import Headline from '@hoffman/shared/src/components/TextFeatures/headline';
import Copy from '@hoffman/shared/src/components/TextFeatures/copy';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.compat.css';
interface QuotePageProps {
  headlineSize: string;
  headlineText: string;
  copySize: string;
  copyText: string;
  quoteColor: string;
  quoteSize: string;
  blockWidth?: string;
}

const QuotePageSection = ({
  headlineSize,
  headlineText,
  copySize,
  copyText,
  quoteColor,
  quoteSize,
  blockWidth,
}: QuotePageProps) => {
  return (
    <ResponsiveContainer>
      <Container blockWidth={blockWidth}>
        <ScrollAnimation
          animateIn="fadeInLeft"
          animateOnce={true}
          duration={2}
          initiallyVisible={false}
          offset={10}
          delay={0}
        ></ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInLeft"
          animateOnce={true}
          duration={2}
          initiallyVisible={false}
          offset={10}
          delay={5}
        >
          <Headline text={headlineText} size={headlineSize} />
        </ScrollAnimation>
        <br />
        <ScrollAnimation
          animateIn="fadeInLeft"
          animateOnce={true}
          duration={2}
          initiallyVisible={false}
          offset={10}
          delay={10}
        >
          <StyledDiv>
            <Copy text={copyText} size={copySize} />
          </StyledDiv>
        </ScrollAnimation>
      </Container>
    </ResponsiveContainer>
  );
};

QuotePageSection.defaultProps = {
  headlineSize: '50px',
  headlineText: 'Headline1',
  copySize: '20px',
  copyText: 'Add Copy Text Here',
  quoteColor: 'black',
  quoteSize: '50',
};

export default QuotePageSection;

const ResponsiveContainer = styled.div``;

const Container = styled.div`
  max-width: ${(props: QuotePageProps) => props.blockWidth};
  svg {
    @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
      width: 30px;
    }
  }
`;

const StyledDiv = styled.div`
  p {
    letter-spacing: 10px;
    text-transform: uppercase;
  }
`;
