import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Headline from "@hoffman/shared/src/components/TextFeatures/headline"

const DesignInteriorHeadlineBlock = props => {
  const { dataJson } = useStaticQuery<
    GatsbyTypes.DesignInteriorHeadlineBlockQuery
  >(graphql`
    query DesignInteriorHeadlineBlock {
      dataJson(slug: { eq: "design" }) {
        slug
        panels(name: "designInteriorHeadlinePanel") {
          name
          models {
            type
            fields {
              text
            }
          }
        }
      }
    }
  `)
  const items = dataJson.panels[0].models.reduce(
    (acc: object, m: { type: string; fields: any }) => {
      acc[m.type] = m.fields
      return acc
    },
    {}
  )

  return (
    <StyledContainer>
      <Headline text={items.headline?.text} size="37px" />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  margin-top: ${({ theme }) => theme.spaces[9]}px;
`

export default DesignInteriorHeadlineBlock
